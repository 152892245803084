<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over" class="sidenav" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar  *ngIf="(isScreen$ | async)?.matches" [ngStyle]="{'background': productionMode ? '#0277BD' : 'green'}" color="primary">
      <!--<button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="sidenav.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>-->

      <span>EOLA</span><span *ngIf="enterpriseView">&nbsp; : &nbsp;</span><a *ngIf="enterpriseView" mat-list-item routerLink="/">Enterprise View</a><span *ngIf="plantView">&nbsp; : &nbsp;</span><a *ngIf="plantView" mat-list-item routerLink="/plantView/{{plant}}">{{plant}}</a><span *ngIf="detailsView">&nbsp; : &nbsp;</span><span *ngIf="detailsView">{{details}}</span><span *ngIf="severityView">&nbsp; : &nbsp;</span>
        <span *ngIf="severityView">{{severityInfo}}</span>

              <span class="spacer"></span>
          <button
            type="button"
            mat-icon-button
            (click)="goAdmin()">
              <mat-icon style="font-size: 32px;" area-label="Home Icon">settings_applications</mat-icon>
          </button>
    </mat-toolbar>
    <mat-toolbar  *ngIf="(isTablet$ | async)?.matches" [ngStyle]="{'background': productionMode ? '#0277BD' : 'green'}" color="primary">
      <span><a routerLink="/">EOLA</a></span>
        <span class="spacer"></span>
        <button
            type="button"
            mat-icon-button
            (click)="goAdmin()">
              <mat-icon style="font-size: 32px;" area-label="Home Icon">settings_applications</mat-icon>
          </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
