<div class="fx-column-center-space-around">
    <div id="detailsTable">
      <table mat-table class ="full-width-table" [dataSource]="detailsDataSource" matSort aria-label="Elements">
         <!-- Result Date Time Column -->
         <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}" >
          <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
          <td mat-cell *matCellDef="let row">{{row[disCol]}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <br>
  <div  class="fx-row-stretch-space-around">
    <button  *ngIf=" firstimage == 1"
    color="primary" (click)="getimage('Image1')" ><mat-icon>picture_in_picture</mat-icon>Picture1</button>
    <img  class="fx-row-stretch-space-around" *ngIf="pic1click" [src]="imageSrc"  alt="sampleapplianceimage" width="auto" height="auto"/>  
    <button  *ngIf=" secondimage == 1"
    color="primary" (click)="getimage('Image2')" ><mat-icon>picture_in_picture</mat-icon>Picture2</button>
    <img  class="fx-row-stretch-space-around" *ngIf="pic2click" [src]="imageSrc"  alt="sampleapplianceimage" width="auto" height="auto"/>  
 </div>
  <div class="fx-row-center-center"><button mat-raised-button color="primary" (click)="close()">Close</button></div>

