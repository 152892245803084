<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="over">
      <mat-toolbar>Menu</mat-toolbar>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/charts/trendChart/', plant]">
          Trend Chart
        </a>
      </mat-nav-list>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/charts/severityTrendChart/', plant]">
            Severity Trend Chart
        </a>
      </mat-nav-list>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/charts/siteTrendChart/', plant]">
            Site Trend Chart
        </a>
      </mat-nav-list>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/charts/sampleRate/', plant]">
            Sample Rate
        </a>
      </mat-nav-list>      
    </mat-sidenav>
  
    <mat-sidenav-content>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="sidenav.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>


