import { Component } from '@angular/core';
import { TrendReportService } from '../services/trend-report.service'
import { trendChartDataArr } from '../services/trendReport.modal';
import { ActivatedRoute } from '@angular/router';
import { LocationInformationService } from '../services/location-information.service';
import { UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import * as dayjs from 'dayjs' 
import { LineSelected } from '../models/genericLamba';


@Component({
  selector: 'app-trend-chart',
  templateUrl: './trend-chart.component.html',
  styleUrls: ['./trend-chart.component.scss']
})
export class TrendChartComponent  {
  dataSource: any;
  chartConfig: Object;
  trendChartData: any;
  trendChartDataObj: trendChartDataArr;
  trendChartDataArrAllShifts: any[] = [];
  trendChartDataArr1stShift: any[] = [];
  trendChartDataArr2ndShift: any[] = [];
  trendChartDataArr3rdShift: any[] = [];
  shiftDateLabels: any[] = [];
  plant: string;

  public fromDateString: string;
  public toDateString: string;
  
  showChart: boolean = false;

  public lineForm: UntypedFormGroup;
  public line: LineSelected ;
  public YAxisVal: number = 0;

  constructor( private fb: UntypedFormBuilder, private locationInformationService: LocationInformationService, private trendReportService: TrendReportService, private route: ActivatedRoute) {
    this.plant = this.route.snapshot.paramMap.get("plant");
    if (this.route.snapshot.paramMap.get("line") !== undefined && this.route.snapshot.paramMap.get("line") !== null){
      this.line={
        "lineSelected": this.route.snapshot.paramMap.get("line")
      }

    } else{
      this.line={
        "lineSelected": ""
      } 
    }

    this.locationInformationService.changeLocation(true);
    this.locationInformationService.changePlant(this.plant);
    this.locationInformationService.changePlantView(true);
  }

  ngOnInit() {
    this.fromDateString =dayjs().subtract(7,'d').format('YYYY-MM-DD');
    this.toDateString = dayjs().format('YYYY-MM-DD');
    this.lineForm = this.fb.group({
      lineSelected: this.line["lineSelected"]
    });

    this.lineForm.valueChanges.subscribe(val => {
      this.line = val
    });

    this.dataSource = {
      "chart": {
      "caption": "EOLA Score By Plant",
      "legendPosition": "right",
      "xAxisName": "Date",
      "yAxisName": "EOLA Score",
      "theme": "fusion",
      "yAxisMaxValue": "1,000,000",
      "yAxisMinValue": this.YAxisVal,
      "showValues": "0",
      },
      "categories": [
        {
          "category": this.shiftDateLabels
        }
      ],
      "dataset": [
        {
          "seriesname": "All Shifts",
          "data": this.trendChartDataArrAllShifts
        },
        {
          "seriesname": "1st Shift",
          "data": this.trendChartDataArr1stShift
        },
        {
          "seriesname": "2nd Shift",
          "data": this.trendChartDataArr2ndShift
        },
        {
          "seriesname": "3rd Shift",
          "data": this.trendChartDataArr3rdShift
        }
      ]
    }
  }

  changeLineSelection(){
    this.fetch();
  }

  fetch() {
    this.trendChartDataArrAllShifts.length = 0
    this.trendChartDataArr1stShift.length = 0
    this.trendChartDataArr2ndShift.length = 0
    this.trendChartDataArr3rdShift.length = 0

    var lineSelect: string
    var groupBy: string

    if (typeof this.line !== 'undefined'){
      lineSelect = this.line["lineSelected"]
      groupBy = null
    } else{
      lineSelect = null
      groupBy = null
    }

    this.trendReportService.trendDateRange(this.plant, this.fromDateString, this.toDateString, groupBy, lineSelect).subscribe((response) => {
      this.trendChartData = response.Body
      this.shiftDateLabels.length = 0

      if (this.trendChartData.length > 0) {
        this.shiftDateLabels = this.trendChartData.map( obj => obj.ShiftDate.substring(5,10)).filter((elem, index, self) => { return index === self.indexOf(elem); }); // Get unique list of shift dates
        this.shiftDateLabels = this.shiftDateLabels.map(obj => { return { 'label': obj } }); // Format the shift dates for fusion chart

        // Extract All Shifts Data
        this.trendChartDataArrAllShifts = this.trendChartData.reduce((accumulator, obj) => {
          if (obj.Shift != '') {
            const existingData = accumulator.find(item => item.label === obj.ShiftDate);
            if (existingData) {
              existingData.value += obj.Score;
            } else {
              accumulator.push({ 'label': obj.ShiftDate, 'value': obj.Score });
            }
          }
          return accumulator;
        }, []);

        // Extract 1st Shift Data
        this.trendChartDataArr1stShift = this.trendChartData.map( obj => { 
          if (obj.Shift == '1ST SHIFT') {
            return { 'label': obj.ShiftDate, 'value': obj.Score }
          } else {
            return false;
          }
        }).filter((elem) => elem != false);

        // Extract 2nd Shift Data
        this.trendChartDataArr2ndShift = this.trendChartData.map( obj => { 
          if (obj.Shift == '2ND SHIFT') {
            return { 'label': obj.ShiftDate, 'value': obj.Score }
          } else {
            return false;
          }
        }).filter((elem) => elem != false);

        // Extract 3rd Shift Data
        this.trendChartDataArr3rdShift = this.trendChartData.map( obj => { 
          if (obj.Shift == '3RD SHIFT') {
            return { 'label': obj.ShiftDate, 'value': obj.Score }
          } else {
            return false;
          }
        }).filter((elem) => elem != false);

        this.YAxisVal = Math.max.apply(Math, this.trendChartData.map(o => o.Score)) + 1; // Get maximum count of any type of issue;

        if(this.YAxisVal <= 0 || this.YAxisVal === Infinity){
          this.YAxisVal = 0
        } else{
          this.YAxisVal = this.YAxisVal
        }

        this.dataSource = {
          "chart": {
          "caption": "EOLA Score By Plant",
          "legendPosition": "right",
          "xAxisName": "Date",
          "yAxisName": "EOLA Score",
          "theme": "fusion",
          "yAxisMaxValue": "1,000,000",
          "yAxisMinValue": this.YAxisVal,
          "showValues": "0",
          },
          "categories": [
            {
              "category": this.shiftDateLabels
            }
          ],
          "dataset": [
            {
              "seriesname": "All Shifts",
              "data": this.trendChartDataArrAllShifts
            },
            {
              "seriesname": "1st Shift",
              "data": this.trendChartDataArr1stShift
            },
            {
              "seriesname": "2nd Shift",
              "data": this.trendChartDataArr2ndShift
            },
            {
              "seriesname": "3rd Shift",
              "data": this.trendChartDataArr3rdShift
            }
          ]
        }       
        this.showChart = true;
      } else {
        this.showChart = false;
      }                         
    }) 
  }

  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');
    this.fetch();
  }
}