<div class="fx-column-center-space-around">
    <div id="AcknowledgeTable">
      <table mat-table class ="full-width-table" [dataSource]="AcknowledgeDataSource" matSort aria-label="Elements">
         <!-- Result Date Time Column -->
         <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}" >
          <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
          <td mat-cell *matCellDef="let row">{{row[disCol]}}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <br>
  <form [formGroup]="commentformgroup"class="comment-form">
    <mat-form-field class="comment-full-width" appearance="fill">
      <mat-label>Add/Update acknowldgement comment</mat-label>
      <textarea matInput placeholder="Acknowledge reason" formControlName="formcomment" required></textarea>
    </mat-form-field>
    <div>
        <small class="error" *ngIf="commentformgroup.get('formcomment').hasError('required')">*Comment is Required </small>
    </div>

  </form>
  <br>
<div class="fx-row-space-between-center ">
    <button mat-raised-button color="primary" (click)="close()">Close</button>
<div>      
     <button mat-raised-button color="primary" [disabled]="commentformgroup.status === 'INVALID'"(click)="saveacknowledgedetails()">Save</button>
    </div>
</div>