<!--The content below is only a placeholder and can be replaced.-->
<gea-mes-header 

showLogOut="desktop"
showMenu="both"
showLeftIcon1="both"
leftIcon1ToolTip="Home Page"
LeftIcon1Src="apps"
routeLeftIcon1="apps"

showRightIcon1="both"
rightIcon1ToolTip="Admin Configuration"
rightIcon1Src="settings_applications"
routeRightIcon1="admin"
>
</gea-mes-header>
<router-outlet></router-outlet>
