import { Component, OnInit, OnDestroy, ViewChild, Directive, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, pluck } from 'rxjs/operators';
import { Observable, Subscription, forkJoin} from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GenericLambdaReturn } from '../models/genericLamba';
import { SeverityViewService, SeverityType, NewDetailType, DetailConfigType, AcknowledgeDetailType } from '../services/severity-view.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DetailsTable } from './details-table.component'
import {AcknowledgeTable} from './acknowledge-table.component'
import { Router } from '@angular/router';
import { LocationInformationService } from '../services/location-information.service';
import * as dayjs from 'dayjs';
import { defaultRippleAnimationConfig } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';



@Component({
  selector: 'app-severity-view',
  templateUrl: './severity-view.component.html',
  styleUrls: ['./severity-view.component.scss']
})
export class SeverityViewComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<SeverityType>
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  mDataSource: SeverityType[]
  subscription: Subscription;
  detailsSubscription: Subscription;
  currentShift: string = "True";  
  dateString: string;
  public fromDateString: string;
  public toDateString: string;
  private location: string;
  public plant: string;
  public line: string;
  public severity: string;
  severityDetailsArr: any[];
  detailColumns: DetailConfigType[];
  severityInfo:string


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['ResultDateTime', 'Shift', 'Line',  'Model', 'Serial', 'AuditType','Severity', 'Acknowledge','AreaDescription', 'ComponentDescription', 'DefectDescription',  'OperatorName', 'Notes', 'Details'];
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])

  constructor(private service: SeverityViewService,
              private breakpointObserver: BreakpointObserver, 
              private locationInformationService: LocationInformationService, 
              private route: ActivatedRoute,
              private router: Router,
              private detailsDialog: MatDialog,
              private AcknowledgeDialog:MatDialog){
  }

  ngOnInit(){
    this.plant = this.route.snapshot.paramMap.get('plant')
    this.route.queryParams.subscribe(params => this.severity=params['Severity'])
    this.route.queryParams.subscribe(params => this.line=params['Line'])
    console.log ( ' Value of severeity ', this.severity )
    setTimeout(() => 
      { this.locationInformationService.changeLocation(true);
        this.locationInformationService.changePlant(this.plant);
        this.locationInformationService.changePlantView(true);
    
        if (typeof this.line !== "undefined"){
          this.locationInformationService.changeDetailsView(true);
          this.locationInformationService.changeDetailsInfo(this.line)
        } else{
          this.locationInformationService.changeDetailsView(false);
        }
        
    
        if (typeof this.severity !== "undefined"){
          this.locationInformationService.changeSeverityView(true);
          this.locationInformationService.changeSeverityInfo(this.severity)
        } else{
          this.locationInformationService.changeSeverityView(true);
          this.locationInformationService.changeSeverityInfo('All');
        }
        this.locationInformationService.severityInfo$.subscribe((severityInfo) => {
          this.severityInfo = severityInfo
        });
      })

    this.dataSource = new MatTableDataSource<SeverityType>();

    this.isTablet$ = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])

    this.getDetailConfig()

  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  fetch(){
    console.log('I run now.')
    let d: Observable<GenericLambdaReturn<SeverityType[]>> =
      this.route.paramMap.pipe(
        switchMap((params:ParamMap) =>
        this.service.getDetailData(this.currentShift, this.fromDateString + ' 00:00:00', this.toDateString + ' 23:59:59.999', this.plant, this.line, this.severity))
      );
    
    
      if (this.subscription) this.subscription.unsubscribe();

    this.subscription = d.subscribe((next) => {
      console.log(next.Body)
      this.dataSource.data=next.Body;
      
      this.mDataSource = next.Body;
      
      this.severityDetailsArr = next.Body
      this.dateString = this.fromDateString + '-' + this.toDateString
    })
  }

  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');
    if (e.currentShift) {
      this.currentShift = "True";
    } else {
      this.currentShift = "False";
    }
    this.fetch();
  }

  displayAcknowledge(acknowlegde: AcknowledgeDetailType[] , ResultId:string,Site:string , Acknowledgeresultdid:string){
    let AcknowledgeArray: any = [];
    let tabSize: number = 0;
    let strTabSize: string;

    AcknowledgeArray = acknowlegde;
    tabSize = 250 * 4;
    strTabSize = `${tabSize.toString()}px`

    const tableRef = this.AcknowledgeDialog.open(AcknowledgeTable, {
      width: strTabSize,
      data: 
      { 
        acknowledge: AcknowledgeArray,
        resultid: ResultId,
        site:Site,
        acknowlegderesultid :Acknowledgeresultdid
      }
    });
    tableRef.afterClosed().subscribe(() => { this.fetch(); } );
  }
  
  displayDetails(details: NewDetailType[],image1: number, image2: number,resultid:string,site:string){
    let detailArray: any = [];
    let detailObject: any;
    let columns: any = [];
    let numColumns: number = 0;
    let tabSize: number = 0;
    let strTabSize: string;
    let Image1 :number = image1;
    let Image2 :number = image2;
    let Resultid: string =resultid;
    let Site: string = site;

    details.forEach(detail => {
      detailObject = {}
      this.detailColumns.forEach(column =>{
        if (column.Description != "" && column.Description != " "){
          if (column.DataField != undefined)
            detailObject[column.Description] = detail[column.DataField]
          numColumns = numColumns + 1
        }
      });
      if (detailObject != undefined){
        detailArray.push(detailObject)
      }
    })

    for (let key in detailArray){
      console.log(' value of detailArray ',detailArray[key])
      if (detailArray[key] != undefined)
        columns.push({key: key, value: detailArray[key]})
    }
    tabSize = 250 * numColumns;
    strTabSize = `${tabSize.toString()}px`
    const tableRef = this.detailsDialog.open(DetailsTable, {
      width: strTabSize,
      data: 
      { 
        details: detailArray,
        image1 : Image1,
        image2  : Image2,
        resultId : Resultid,
        site :Site
      }
    });

  }

  getDetailConfig(){
    let dataDescription: DetailConfigType[]

    let detailsObservable: Observable<DetailConfigType[]> =
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.service.getDetailConfig(this.plant)),
        pluck('Body'));
    
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();

    this.detailsSubscription = detailsObservable.subscribe((out) => {
      this.detailColumns = out 
    });
  }

  navToAnalysis(){
    this.router.navigate(['/analysis/'+this.plant], { queryParams: {Severity: this.severity, Line: this.line}})

  }
  
}

