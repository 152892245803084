import { Component, OnInit } from '@angular/core';
import { TrendReportService } from '../services/trend-report.service'
import { ActivatedRoute } from '@angular/router';
import { LocationInformationService } from '../services/location-information.service';
import * as dayjs from 'dayjs';

export interface chartDataSource{
  seriesname: string;
  data: any[];
}

@Component({
  selector: 'app-severity-trend-chart',
  templateUrl: './severity-trend-chart.component.html',
  styleUrls: ['./severity-trend-chart.component.scss']
})

export class SeverityTrendChartComponent implements OnInit {
  dataSource: any;
  chartConfig: Object;
  trendChartData: any;

  shiftDateLabels: any[] = [];
  trendChartDataSource: chartDataSource[] = [];
  plant: string;

  showChart: boolean = false;

  public fromDateString: string;
  public toDateString: string;

  public yMaxVal: number
  public groupBy: string = "day"

  constructor( private locationInformationService: LocationInformationService, private trendReportService: TrendReportService, private route: ActivatedRoute) {
    this.plant = this.route.snapshot.paramMap.get("plant")
    this.locationInformationService.changeLocation(true);
    this.locationInformationService.changePlant(this.plant);
    this.locationInformationService.changePlantView(true);
  }

  ngOnInit() {
    this.dataSource = {
      "chart": {
      "caption": this.plant + " Severity Chart",
      "legendPosition": "right",
      "xAxisName": "Date",
      "yAxisName": "EOLA Finding By Catergory",
      "theme": "fusion",
      "yAxisMaxValue": "0",
      "yAxisMinValue": "0",
      "showValues": "0",
      },
      "categories": [
        {
          "category": this.shiftDateLabels
        }
      ],
      "dataset": [{
        'seriesname': 'Major',
        'data': []
      }
      ]
    }     
  }

  getSeverityTrendData() {
    this.trendReportService.trendDateRange(this.plant, this.fromDateString, this.toDateString, this.groupBy).subscribe((response) => {
      this.trendChartData = response.Body;
      this.shiftDateLabels.length = 0;
      this.trendChartDataSource = [];

      if (this.trendChartData.length > 0) {
        this.shiftDateLabels = this.trendChartData.map( obj => obj.ShiftDate.substring(5,10)).filter((elem, index, self) => { return index === self.indexOf(elem); }); // Get unique list of shift dates
        this.shiftDateLabels = this.shiftDateLabels.map(obj => { return { 'label': obj } }); // Format the shift dates for fusion chart

        this.trendChartDataSource.push({
          'seriesname': 'Major',
          'data': this.trendChartData.map( obj => { return { 'label': obj.ShiftDate, 'value': obj.Major } })
        });

        this.trendChartDataSource.push({
          'seriesname': 'Critical',
          'data': this.trendChartData.map( obj => { return { 'label': obj.ShiftDate, 'value': obj.Critical } })
        });

        this.trendChartDataSource.push({
          'seriesname': 'Minor',
          'data': this.trendChartData.map( obj => { return { 'label': obj.ShiftDate, 'value': obj.Minor } })
        });

        this.yMaxVal = Math.max.apply(Math, this.trendChartData.map(o => { return Math.max(o.Minor, o.Major, o.Critical) })) + 1; // Get maximum count of any type of issue

        this.dataSource = {
          "chart": {
          "caption": this.plant + " Severity Chart",
          "legendPosition": "right",
          "xAxisName": "Date",
          "yAxisName": "EOLA Finding By Catergory",
          "theme": "fusion",
          "yAxisMaxValue": this.yMaxVal.toString,
          "yAxisMinValue": "0",
          "showValues": "0",
          },
          "categories": [
            {
              "category": this.shiftDateLabels
            }
          ],
          "dataset": this.trendChartDataSource
        }      
        
        this.showChart = true;
      } else {
        this.showChart = false;
        this.shiftDateLabels = [];
        this.yMaxVal = 1;
        this.trendChartDataSource =[{'seriesname': 'Major','data': []}]; // Dummy data for blank
      }
  }) 

  }

  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');

    this.getSeverityTrendData();
  }
  
}
