import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TrendService } from '../services/trend.service';
import { ActivatedRoute } from '@angular/router';
import * as dayjs from 'dayjs' 

@Component({
  selector: 'app-trend-sample-rate',
  templateUrl: './trend-sample-rate.component.html',
  styleUrls: ['./trend-sample-rate.component.scss']
})
export class TrendSampleRateComponent implements OnInit {

  subscription:Subscription;
  
  dataSampleRate:any;

  chartConfig: Object;
  dataSource: any;  

  lines: [{ "Line": string}];
  
  plant: string;

  fromDateString: string;
  toDateString: string;

  selectedLine:string="";

  showChart: boolean = false;

  constructor(
    private service:TrendService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.plant = this.route.snapshot.paramMap.get("plant");

    this.dataSource = {
        "chart": {
          "showValues": "1",
            "caption": "EOLA Sample Rate",
            "legendPosition": "right",
            "xaxisname": "Shift",
            "yaxisname": "Sample Rate (%)",
            "numbersuffix": "%",
            "theme": "fusion"
        },
        "categories": [
            {
                "category": [{
                    "label": "NONE"
                }]          
            }
        ],
        "dataset": [
            {     
                "dataset":[
                    {
                        "dataset": [
                            {
                              "seriesname": "No Data",
                                "data": [{ "value": 0}]
                            }
                        ]
                    }]
            }
          ]
        };

    this.service.getLines(this.plant).subscribe( (out) => {
        console.log("Lines", out);
        this.lines = out.Body;
        
        if (this.lines.length >= 1) {
            this.selectedLine = this.lines[0].Line;
        }
        
        this.fetch();
    });
  }

  fetch() {
    if (this.subscription) this.subscription.unsubscribe();

    if (this.selectedLine != "") {
        this.subscription = this.service.getSampleRate(this.plant, this.fromDateString, this.toDateString,this.selectedLine).subscribe( (out) => {
            this.dataSource = {
            "chart": {
                "showValues": "1",
                "caption": "EOLA Sample Rate",
                "legendPosition": "right",
                "xaxisname": "Shift",
                "yaxisname": "Sample Rate (%)",
                "numbersuffix": "%",
                "theme": "fusion"
            },
            "categories": [
                {
                    "category": out.Body.shiftData          
                }
            ],
            "dataset": [
                {     
                    "dataset": out.Body.operatorData
                }
                ]
            };

            this.showChart = true;
        });
    }
  }

  changeLine() {
      this.fetch();
  }

  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');
    this.fetch();
  }

  ngOnDestory() {
    if (this.subscription) this.subscription.unsubscribe();
  }



}
