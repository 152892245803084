<div class="fx-column" id="trendChart">
  <div class="fx-row-space-evenly-center" id="options">
    <gea-mes-datepicker 
      matTooltip="Select Date" 
      (onDateSelect)="onDateSelection($event)" 
      (onDateInit)="onDateSelection($event)"
      singleDate="false" 
      showLabel="true"
      showCurrentShift="true"
      defaultRange="Current Shift"
      timeRangeOptionalInclude="Rolling 6 Months,Rolling 12 Months,Year to Date"
      showRangeLabel="true">
    </gea-mes-datepicker>
  </div>
  
    
  <div class="fx-column-center-center">
    <div *ngIf="showChart" class="mat-elevation-z8 table-container" id="enterpriseTable">
      <fusioncharts 
      width="100%" 
      height="85%" 
      type="msline"
      [chartConfig]=chartConfig
      [dataSource]=dataSource>
      </fusioncharts> 
    </div>
    <div *ngIf="!showChart" class="mat-elevation-z8 table-container">
      <h2>No Data</h2>
    </div>
  </div>
</div>