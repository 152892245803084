import { Component,Inject } from '@angular/core';
import { NavItemService } from './nav-item.service';
import { Router, NavigationEnd } from '@angular/router';
import { GEA_MES_NAVBAR_MENU_SERVICE, NavItem } from '@gea-mes/navbar';
import { BehaviorSubject,Subscription } from 'rxjs';
import {​​​​ GeaMesCognitoAuthService }​​​​ from '@gea-mes/cognito';
import { filter } from 'rxjs/operators';
import { appinfo } from './appinfo';

declare var gtag_setValue;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  Version: any;
  title = 'eola-app';
  productionMode: boolean;
  trackingid:string;
  logon$: Subscription;
  
  constructor (
    private router: Router,
    private geaMesCognito: GeaMesCognitoAuthService,
    public navService:NavItemService,
    @Inject(GEA_MES_NAVBAR_MENU_SERVICE) private NavItem: BehaviorSubject<NavItem[]> 
   )
   {
    NavItem.next(navService.navItems);
    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    this.title = 'EOLA'
    this.Version = appinfo.version;
    
    console.log("Version: ", this.Version)
   }
    ngOnInit(): void {
      gtag_setValue({"appversion": appinfo.version});

    this.logon$ = this.geaMesCognito.sessionEstablished$.subscribe( out => {
      this.trackingid = this.geaMesCognito.getUserAttribute("trackingid");
      if (this.trackingid != undefined) {
        gtag_setValue({"sub": this.trackingid});
        if (this.logon$) this.logon$.unsubscribe(); // We've done the work we need to, can unsubscribe
      }       
    });

  }
}