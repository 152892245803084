<div class="fx-row-start-center" color="primary" >
  <div>
   <a    color="primary"
 
    mat-list-item
    routerLink="/apps"
    >
    EOLA : Enterprise View : 
  </a>
  </div>
  <div>
   <a    color="primary"

    mat-list-item
    routerLink="/plantView/{{plant}}"
    >
    {{plant}} 
  </a>
  </div>
</div> 
<div class="fx-column">
  <div class="fx-row-space-evenly-center" id="options">
    <gea-mes-datepicker 
      matTooltip="Select Date" 
      (onDateSelect)="onDateSelection($event)" 
      (onDateInit)="onDateSelection($event)"
      singleDate="false" 
      showLabel="true"
      showCurrentShift="true"
      defaultRange="Current Shift"
      timeRangeOptionalInclude="Rolling 6 Months,Rolling 12 Months,Year to Date"
      showRangeLabel="true">
    </gea-mes-datepicker>
    <a routerLink="/charts/trendChart/{{plant}}">
      <button
      mat-raised-button
      color ="primary"
      >
        Trend Chart
      </button> 
    </a>
    <app-save-as-csv [dataToWrite]="plantDataArr" [dataSource]="'Plant Overview'" [dateTimeRange]="dateString"></app-save-as-csv>
  </div>  

  <div class="fx-column-center-center">
    <div class="mat-elevation-z8 table-container" id="plantTable">
      <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">
        <!-- Site Column -->
        <ng-container matColumnDef="site">
          <th mat-header-cell *matHeaderCellDef style="text-align: center">Line</th>
          <td mat-cell 
            *matCellDef="let row" 
            style="text-align: center"
            (click)="changeLocation('Plant View')">
            <a routerLink="/details/{{row.site}}" [queryParams]="{Line: row.Line}">{{row.Line}}</a>
          </td>
        </ng-container>

        <!-- Minor Column -->
        <ng-container matColumnDef="minor">
          <th mat-header-cell *matHeaderCellDef style="text-align: center">Minor</th>
          <td mat-cell 
              *matCellDef="let row" 
              style="text-align: center">
              <a routerLink="/details/{{row.site}}" [queryParams]="{Severity: 'Minor', Line: row.Line}">{{row.Minor}}</a>
          </td>
        </ng-container>

        <!-- Major Column -->
        <ng-container matColumnDef="major">
          <th mat-header-cell *matHeaderCellDef style="text-align: center" >Major</th>
          <td mat-cell *matCellDef="let row" style="text-align: center">
            <a routerLink="/details/{{row.site}}" [queryParams]="{Severity: 'Major', Line: row.Line}">{{row.Major}}</a>
          </td>
        </ng-container>

        <!-- Critical Column -->
        <ng-container matColumnDef="critical" >
          <th mat-header-cell *matHeaderCellDef style="text-align: center">Critical</th>
          <td mat-cell *matCellDef="let row" style="text-align: center">
            <a routerLink="/details/{{row.site}}" [queryParams]="{Severity: 'Critical', Line: row.Line}">{{row.Critical}}</a>
          </td>
        </ng-container>

        <!-- Acknowledged Column -->
        <ng-container matColumnDef="acknowledged" >
          <th mat-header-cell *matHeaderCellDef style="text-align: center">Acknowledged</th>
          <td mat-cell *matCellDef="let row" style="text-align: center">
            <a routerLink="/details/{{row.site}}" [queryParams]="{Severity: 'Critical', Line: row.Line}"
            [ngClass]="{
              'acknowledge-red' : row.Critical + row.Major > row.acknowledged,
              'acknowledge-green' : row.Critical + row.Major == row.acknowledged
                }"
            >{{row.acknowledged}}</a>
           </td>
        </ng-container>

        <!-- Total Column -->
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef style="text-align: center">Total Units Audited</th>
          <td mat-cell *matCellDef="let row" style="text-align: center">
            <a routerLink="/details/{{row.site}}" [queryParams]="{Line: row.Line}">{{row.Total}}</a>
          </td>
        </ng-container>

        <!-- Score Column -->
        <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef style="text-align: center">Score</th>
          <td mat-cell *matCellDef="let row" style="text-align: center">{{row.Score | number:'1.'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
