<div class="fx-column-center-center">

  <form>
    <mat-form-field class="margin">
      <mat-label>Site</mat-label>
      <mat-select (selectionChange)="selectChange($event)" [(value)]="siteSelect" >
          <mat-option *ngFor="let opt of options" [value]="opt">{{opt}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
<div class="mat-elevation-z8 table-container" *ngIf="siteSelect">

    <button mat-raised-button color="primary" type="button" class="margin" (click)="onSubmit()" >Save</button>
  <div><span style="color:red">{{errorMsg}}</span></div>

<table mat-table [dataSource]="dataSource" class="full-width-table" >

  <ng-container matColumnDef="AreaDescription">
    <th mat-header-cell *matHeaderCellDef> Area Description </th>
    <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
  </ng-container>

  <ng-container matColumnDef="AreaCode">
    <th mat-header-cell *matHeaderCellDef> Area Code </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field>
        <input style="text-align:center" matInput name="AreaCode" [(ngModel)]="element.AreaCode" (ngModelChange)="areaCodeChange($event,element.Description)">
      </mat-form-field>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

    </div>

  </div>
