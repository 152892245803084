import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';


@Injectable({
  providedIn: 'root'
})
export class AdminService extends AbstractRestService {
  postInit(): void {}

  getAreaConfigData(Site: string) :Observable<GenericLambdaReturn<AdminAreaType[]>> {
    let params:any={
      "CurrentShift": "True",
      "Site": Site
    };

    return this.get({
      ApiResource: '/getareaconfig',
      data: params,
      repeatCall: false
    });
  }

  areaUpdate(data: AdminAreaType, Site:string):Observable<GenericLambdaReturn<AdminAreaType[]>>{
    let params:any = {
      "data": {
        "Description": data.Description,
        "AreaCode": data.AreaCode,
        "Site": Site
      }
    }

    return this.post({
      ApiResource: '/areaupdate',
      data: params
    });
  }
}

export type AdminAreaType = {
  Description: string;
  AreaCode: string;
}
