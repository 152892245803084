import { Component, Inject,OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SeverityViewService } from '../services/severity-view.service';


@Component({
  selector: 'app-acknowledge-table',
  templateUrl: './acknowledge-table.component.html',
  styleUrls: ['./acknowledge-table.component.scss']
})
export class AcknowledgeTable {
  
  Resultid: string;
  AcknowledgeDataSource: any[];
  displayedColumns = [];
  commentformgroup: UntypedFormGroup;
  commententered :string;
  userName:string
  userNameStatus =false;
  sessionActive$: Subscription;
  Site: string;
  acknowledgeresultid :string;
  
  constructor(public dialogRef: MatDialogRef<AcknowledgeTable>,
    @Inject(MAT_DIALOG_DATA) public AcknowledgeData: AcknowledgeDialogData,    
    private commentFormBuilder: UntypedFormBuilder,private cognito: GeaMesCognitoAuthService,
    private service: SeverityViewService
  )
  
  { 
    console.info(AcknowledgeData)
    this.AcknowledgeDataSource = AcknowledgeData.acknowledge
    this.Resultid = AcknowledgeData.resultid
    this.Site = AcknowledgeData.site
    this.acknowledgeresultid = AcknowledgeData.acknowlegderesultid

    if(this.AcknowledgeDataSource[0] !=null)
    {
      this.displayedColumns = Object.keys(this.AcknowledgeDataSource[0])
    }
    else
    {
      this.displayedColumns.push ("Comment",
      "Insert Datetime",
      "Update Datetime",
      "User ID")
    }


    
    this.commentformgroup = this.commentFormBuilder.group({
      formcomment: ['', [Validators.required]]
    })
    
    this.commentformgroup.valueChanges.subscribe((formValues) =>{
      this.commententered = formValues.formcomment
    });
  
    this.sessionActive$ = this.cognito.sessionEstablished$.subscribe(x => {
      this.userName= this.cognito.getUserAttribute('sso')
      if(this.userName!=''){
        this.userNameStatus=true;
      }
      if(this.sessionActive$){
        this.sessionActive$.unsubscribe();}
   });

  }
  

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  close(): void {
    this.dialogRef.close();
  }

  saveacknowledgedetails():void {

    if (this.commentformgroup.status == 'VALID'){
 
      
      console.log(this.commententered)
      console.log (this.Resultid)
      console.log(this.userName)
      console.log(this.Site)
      this.service.updateAcknowledgementDetails(this.Site,this.Resultid,this.commententered,this.userName,this.acknowledgeresultid).subscribe();          
      setTimeout(() => {
        this.dialogRef.close()
    }, 3000);

      
    } else {
      console.error('Invalid pitchFormGroup, was not caught in form control error handling.')
    }
  }

  

}

export interface AcknowledgeDialogData {
  acknowledge: any[];
  resultid:string;
  site:string;
  acknowlegderesultid :string;
}

