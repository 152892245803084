import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationInformationService } from '../services/location-information.service';
import {Router} from "@angular/router";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eola-nav',
  templateUrl: './eola-nav.component.html',
  styleUrls: ['./eola-nav.component.scss']
})
export class EolaNavComponent implements OnInit {
  subTitleLocation: string
  enterpriseView: boolean
  plantView: boolean
  plant: string
  detailsView: boolean
  details: string
  severityView: boolean
  severityInfo: string
  productionMode: boolean


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])


  constructor(private router: Router, private breakpointObserver: BreakpointObserver, private locationInformationService: LocationInformationService) {
    this.isTablet$ = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    this.isScreen$ = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
    this.productionMode = environment.production
   }

  ngOnInit(){
    this.locationInformationService.currentLocation$.subscribe((location) => {
      this.enterpriseView = location
    });

    this.locationInformationService.currentPlant$.subscribe((plant) => {
      this.plant = plant
    });

    this.locationInformationService.plantView$.subscribe((plantView) => {
      this.plantView = plantView
    });

    this.locationInformationService.detailsView$.subscribe((detailsView) => {
      this.detailsView = detailsView
    });

    this.locationInformationService.detailsInfo$.subscribe((detailsInfo) => {
      this.details = detailsInfo
    });

    this.locationInformationService.severityView$.subscribe((severityView) => {
      this.severityView = severityView
    })

    this.locationInformationService.severityInfo$.subscribe((severityInfo) => {
      this.severityInfo = severityInfo
    });
  }

  goAdmin(){
    this.router.navigate(["/admin"])
  }

}
