export const environment = {
  production: false,
  apigwUrl: 'https://api-sysval.sc.geappl.io/eola',
  apiGateway: {
    url: "https://api-sysval.sc.geappl.io/",
    stage: "eola"
  },  
  AllowScreenAuth: true,  
  cognitoOptions: {
    ClientId: '6momgtan28o12pevla32ug4pet', // Your client id here
    AppWebDomain: 'mesauth.auth.us-east-1.amazoncognito.com', // Exclude the "https://" part.
    TokenScopesArray: ['openid', 'profile'],
    UserPoolId: 'us-east-1_s1pKZcsUG'
  }
};