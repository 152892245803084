import { Component, OnInit, Input } from '@angular/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-save-as-csv',
  templateUrl: './save-as-csv.component.html',
  styleUrls: ['./save-as-csv.component.scss']
})
export class SaveAsCsvComponent implements OnInit {

  @Input() dataToWrite: any[];
  @Input() dataSource: string;
  @Input() dateTimeRange: string;

  constructor() { }

  ngOnInit() {}

  saveDataToCsv(){
    let data = this.dataToWrite
    console.log(data)
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    console.log(csvArray)
    let blob = new Blob([csvArray], {type: 'text/csv' })
    let fileName = this.dataSource + '_' + this.dateTimeRange + '.csv'
    saveAs(blob, fileName);
}



}
