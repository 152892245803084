import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AnalysisService } from 'src/app/services/analysis.service';
import * as dayjs from 'dayjs';
import FusionChartsEvent from 'src/app/angular-fusioncharts/interfaces/FusionChartsEvent';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { LocationInformationService } from 'src/app/services/location-information.service';

@Component({
  selector: 'app-pareto',
  templateUrl: './pareto.component.html',
  styleUrls: ['./pareto.component.scss']
})

export class ParetoComponent implements OnInit, OnDestroy {

  @Input() DatePickerIndex:number = 0;
  
  dataSource: any;
  formattedData:any[] = [{ label: '', value: 0 }]; // To avoid console erorr, need to default the data to something.
  filteredData:any[] = [{ label: '', value: 0 }]; // To avoid console erorr, need to default the data to something.
  filterData:any[] = [];

  filter = new FormControl('');

  paertoSub$: Subscription;
  paretoData:any = {};

  // Parameters for pulling Pareto data
  plant:string;
  currentShift: string = "True";
  line: string = "ALL";
  severity: string = "ALL"

  public fromDateString: string;
  public toDateString: string;



  labelSeperator:string = '->';
  showDetail:boolean = false;
  showByDuration:boolean = false;

  

  constructor(
    private route: ActivatedRoute,
    private svcAnalysis:AnalysisService,
    private zone: NgZone,
    private locationInformationService: LocationInformationService
  ) { 
    this.setChartData(); // Need to set the default chart config even though we don't have data yet
  }
  
  ngOnInit(): void {
    this.plant = this.route.snapshot.paramMap.get('plant')
    this.route.queryParams.subscribe(params => this.severity=params['Severity'])
    this.route.queryParams.subscribe(params => this.line=params['Line'])
    console.log ( ' Value of severeity ', this.severity )
    setTimeout(() => 
      { this.locationInformationService.changeLocation(true);
        this.locationInformationService.changePlant(this.plant);
        this.locationInformationService.changePlantView(true);
    
        if (typeof this.line !== "undefined"){
          this.locationInformationService.changeDetailsView(true);
          this.locationInformationService.changeDetailsInfo(this.line)
        } else{
          this.locationInformationService.changeDetailsView(false);
        }
        
    
        if (typeof this.severity !== "undefined"){
          this.locationInformationService.changeSeverityView(true);
          this.locationInformationService.changeSeverityInfo(this.severity)
        } else{
          this.locationInformationService.changeSeverityView(true);
          this.locationInformationService.changeSeverityInfo('All');
        }
      })
    this.getParetoData();
  }
 
  applyFilter() {
    this.filteredData = this.formattedData.filter(rec => !this.filter.value.includes(rec.label));
    this.setChartData();
  }


  clearFilter(level:number = 1) {
    this.getParetoData();
  }

  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD'); 
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');
    if (e.currentShift) {
      this.currentShift = "True";
    } else {
      this.currentShift = "False";
    } 
    this.getParetoData();
  }

  getParetoData() {
    if (this.paertoSub$) this.paertoSub$.unsubscribe();

    this.paertoSub$ = this.svcAnalysis.pareto(this.plant,this.currentShift, this.fromDateString + ' 00:00:00', this.toDateString + ' 23:59:59.999',this.line,this.severity).subscribe(
      (data) => {
        this.paretoData = data.Body;

        let myFormattedData:any = [];
        this.formattedData = [];

        this.paretoData.forEach(element => {
          let myLabel:string = element.ComponentDescription + this.labelSeperator + element.DefectDescription;

          let myValue:number = element.TotalIssues;

          myFormattedData.push({
            label:myLabel,
            value: myValue 
          });

          this.formattedData = myFormattedData; //.filter(rec => rec.label != 'Not Entered' );
        });

        console.debug("this.formattedData", this.formattedData);

        this.applyFilter();
      }
    )
  }

  setGrouping(grouping:string) {
    if (grouping == 'duration') {
      this.showByDuration = true;
    } else {
      this.showByDuration = false;
    }
    this.getParetoData();
  }
  
  setChartData() {
    this.dataSource = null;

    this.dataSource = {
      "chart": {
          "caption": "",
          "subCaption": "",
          "xAxisName": "Component + Defect",
          "pYAxisName": "Defects",
          "sYAxisname": "Cumulative Percentage",
          "showHoverEffect": "1",
          "theme": "fusion",
          "labelDisplay": "rotate",
          "plotToolText": "Component + Defect: $label <br> Defects: $value"
      },
      "data": this.filteredData
    };
  }

  drilldown(e:FusionChartsEvent) {
    console.debug("Drilldown!!!",e);
    let clickedItem:string = e.eventObj['data']['index'];
    let levelSelected:string = this.filteredData[clickedItem].label;

    // FusionChart events are note run in the same zone as Angular, need to trigger the refresh in the Angular zone
    this.zone.run(() => {
      this.getParetoData();
    });
  }

  /**
   * Toggles between showing just top level and showing all levels
   */
  toggleDetail() {
    this.showDetail = this.showDetail ? false : true;
    this.getParetoData();
  }
  
  ngOnDestroy(): void { 
    if (this.paertoSub$) this.paertoSub$.unsubscribe();
  }

}
