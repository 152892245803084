import { Component, OnInit } from '@angular/core';
import { TrendReportService } from '../services/trend-report.service';

import * as dayjs from 'dayjs' ;
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { LocationInformationService } from '../services/location-information.service';


export interface chartDataSource{
  seriesname: string;
  data: any[];
}

export interface severityObjs{
  label: string,
  value: string
}

@Component({
  selector: 'app-site-trend-chart',
  templateUrl: './site-trend-chart.component.html',
  styleUrls: ['./site-trend-chart.component.scss']
})

export class SiteTrendChartComponent implements OnInit {
  public plant: string;
  public groupBy: string;
  
  public dataSource: any;
  public chartConfig: Object;

  public trendChartData: any;
  public shiftDateLabels: any[] = [];
  public trendChartDataSource: chartDataSource[] = [];
 
  public fromDateString: string;
  public toDateString: string;

  public lineArr: string[];
  
  public linesSelected: string[] = [];
  public lineSelectForm = new UntypedFormGroup({
      lines: new UntypedFormControl([])
  })
  public lineListElements$ = of([]);
  public linesSelectedArr: any[];
  public maxVal: number;

  showChart: boolean = false;

  constructor(private locationInformationService: LocationInformationService, private trendReportService: TrendReportService, private route: ActivatedRoute) {
    this.plant = this.route.snapshot.paramMap.get("plant");
    this.groupBy = "line, day";

    this.locationInformationService.changeLocation(true);
    this.locationInformationService.changePlant(this.plant);
    this.locationInformationService.changePlantView(true);
    this.dataSource = []
   }

  ngOnInit() {
    this.lineSelectForm.valueChanges.subscribe(val => {
      this.linesSelectedArr = val
    });

    this.dataSource = {
      "chart": {
      "caption": this.plant + " EOLA Findings By Category",
      "legendPosition": "right",
      "xAxisName": "Date",
      "yAxisName": "EOLA Finding By Catergory",
      "theme": "fusion",
      "yAxisMaxValue": "0",
      "yAxisMinValue": "0",
      "showValues": "0",
      },
      "categories": [
        {
          "category": this.shiftDateLabels
        }
      ],
      "dataset": [{
        'seriesname': 'Major',
        'data': []
      }
      ]
    }
  }

  getSeverityTrendData() {
    var lineSelect: string
    if (this.linesSelected.length > 0) {
      lineSelect = this.linesSelectedArr["lines"].toString();
    } else {
      lineSelect = null;
    }

    this.trendReportService.trendDateRange(this.plant, this.fromDateString, this.toDateString, this.groupBy, lineSelect).subscribe((response) => {
      this.trendChartData = response.Body;
      this.shiftDateLabels.length = 0;

      if (this.trendChartData.length > 0) {
        this.lineArr = this.trendChartData.map( obj => obj.Line).filter((elem, index, self) => { return index === self.indexOf(elem); }); // Get unique list of lines
        this.shiftDateLabels = this.trendChartData.map( obj => obj.ShiftDate.substring(5,10)).filter((elem, index, self) => { return index === self.indexOf(elem); }); // Get unique list of shift dates
        this.shiftDateLabels = this.shiftDateLabels.map(obj => { return { 'label': obj } }); // Format the shift dates for fusion chart

        // Format the output for fusion charts
        this.trendChartDataSource = [];
        for(let line in this.lineArr) {
          let critical = this.trendChartData.map( obj => { 
            if (obj.Line == this.lineArr[line]) {
              return { 'label': obj.ShiftDate, 'value': obj.Critical }
            } else {
              return false;
            }
          }).filter((elem) => elem != false);

          let major = this.trendChartData.map( obj => { 
            if (obj.Line == this.lineArr[line]) {
              return { 'label': obj.ShiftDate, 'value': obj.Major }
            } else {
              return false;
            }
          }).filter((elem) => elem != false);

          let minor = this.trendChartData.map( obj => { 
            if (obj.Line == this.lineArr[line]) {
              return { 'label': obj.ShiftDate, 'value': obj.Minor }
            } else {
              return false;
            }
          }).filter((elem) => elem != false);

          this.trendChartDataSource.push({
            "data": major,
            "seriesname": `${this.lineArr[line]} major`
          });

          this.trendChartDataSource.push({
            "data": minor,
            "seriesname": `${this.lineArr[line]} minor`
          });

          this.trendChartDataSource.push({
            "data": critical,
            "seriesname": `${this.lineArr[line]} critical`
          });
        }
        this.maxVal = Math.max.apply(Math, this.trendChartData.map(o => { return Math.max(o.Minor, o.Major, o.Critical) })) + 1; // Get maximum count of any type of issue

        this.dataSource = {
          "chart": {
          "caption": this.plant + " EOLA Findings By Category",
          "legendPosition": "right",
          "xAxisName": "Date",
          "yAxisName": "EOLA Finding By Catergory",
          "theme": "fusion",
          "yAxisMaxValue": this.maxVal.toString(),
          "yAxisMinValue": "0",
          "showValues": "0",
          },
          "categories": [
            {
              "category": this.shiftDateLabels
            }
          ],
          "dataset": this.trendChartDataSource
        }
       
        this.showChart = true;
      } else {
        this.showChart = false;
        this.lineArr = [];
        this.shiftDateLabels = [];
        this.maxVal = 1;
        this.trendChartDataSource =[{'seriesname': 'Major','data': []}]; // Dummy data for blank
      }

      this.lineListElements$ = of(this.lineArr);
    });

    }

  changeLinesDisplayed(){
    this.trendChartDataSource = []
    this.linesSelected = []
    console.log("this.linesSelectedArr", this.linesSelectedArr);    
    this.linesSelected = this.linesSelectedArr["lines"]
    
    this.getSeverityTrendData();
  }

  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');

    this.getSeverityTrendData();
  }

}
