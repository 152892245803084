import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, pluck } from 'rxjs/operators';
import { Observable, Subscription, forkJoin} from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GenericLambdaReturn } from '../models/genericLamba';
import { SeverityViewService, SeverityType, DetailConfigType} from '../services/severity-view.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-alarm-detail-view',
  templateUrl: './alarm-detail-view.component.html',
  styleUrls: ['./alarm-detail-view.component.scss']
})
export class AlarmDetailViewComponent implements OnInit, OnDestroy {

  dataSource: MatTableDataSource<SeverityType>
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  subscription: Subscription;
  detailsSubscription: Subscription;
  currentShift: string = "False";  
  dateString: string;
  public fromDateString: string = '1900-01-01 00:00:00.000';
  public toDateString: string = '1900-01-01 23:59:59.000';
  public plant: string;
  public line: string;
  public resultID: string = '5829010';
  public severity: string;
  detailColumns: DetailConfigType[];
  severityInfo:string
  isMobileView: boolean = true;
  

  firstimage : boolean;
  secondimage : boolean;
  pic:Blob;
  pic1click:Boolean=false;
  pic2click:Boolean=false;
  base64data :any;
  imageSrc1:SafeUrl;
  imageSrc2:SafeUrl;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */ 
   displayedColumns = ['ResultDateTime', 'Shift', 'Line','Severity', 'AuditType', 'Model', 'Serial', 'AreaDescription','ComponentDescription','DefectDescription', 'Details'];

  constructor(private service: SeverityViewService,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer : DomSanitizer){
  }

  
  ngOnInit(){
    this.plant = this.route.snapshot.paramMap.get('plant')
    this.resultID = this.route.snapshot.paramMap.get('resultid')
    this.dataSource = new MatTableDataSource<SeverityType>();
    this.fetch();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  fetch(){
    
    console.log('I run now.')
    let d: Observable<GenericLambdaReturn<SeverityType[]>> =
      this.route.paramMap.pipe(
        switchMap((params:ParamMap) =>
        this.service.getDetailData(this.currentShift, this.fromDateString, this.toDateString, this.plant, 'ALL', 'ALL', this.resultID))
      );

      if (this.subscription) this.subscription.unsubscribe();

      this.subscription = d.subscribe((next) => {      
      this.dataSource.data=next.Body;      
      this.line = this.dataSource.data[0].Line;
      this.severityInfo = this.dataSource.data[0].Severity;
      this.firstimage = this.dataSource.data[0].Image1;
      this.secondimage = this.dataSource.data[0].Image2;

      if (this.firstimage == true){
        this.getimage('Image1');
      }
      else if (this.secondimage == true) {
        this.getimage('Image2');
      }      
    })

  }  



  getimage(image:string){
    let base64data: any

    this.service.getpic(this.resultID,image,this.plant).subscribe(
      
      (blob) => {
        console.debug("Received picture")
        this.pic = blob; // Save the blob to be accessed later
        var reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = (out) => {
          console.log(' In the function ')
          base64data = reader.result;

          if (image == 'Image1'){
            this.imageSrc1 = this.sanitizer.bypassSecurityTrustUrl( base64data);
          }
          if (image == 'Image2'){
            this.imageSrc2 = this.sanitizer.bypassSecurityTrustUrl( base64data);
          }
          
        }          },
      (err) => {
        console.error("Error generating picture", err);

      }
      );
  }

  navToAnalysis(){
    this.router.navigate(['/analysis/'+this.plant], { queryParams: {Severity: this.severity, Line: this.line}})

  }
  
}

