<div class="fx-row-start-center">
    <gea-mes-datepicker
        class="dateRangeBut"
        matTooltip="Select Date" 
        (onDateSelect)="onDateSelection($event)" 
        (onDateInit)="onDateSelection($event)"
        singleDate="false" 
        showLabel="true"
        showCurrentShift="true"
        defaultRange="Today"
        timeRangeOptionalInclude="Rolling 6 Months,Rolling 12 Months,Year to Date"
        showRangeLabel="true"
        autoApply="true">
    </gea-mes-datepicker>
</div>
    
<div>
    <fusioncharts
        height="80%"
        width="100%"
        type="pareto3d"
        dataFormat="JSON"
        [dataSource]="dataSource"
        (dataplotClick)="drilldown($event)">
    </fusioncharts>
</div>