import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, Subscription, forkJoin} from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GenericLambdaReturn } from '../models/genericLamba';
import { EnterpriseViewService, EnterpriseType } from '../services/enterprise-view.service';
import { LocationInformationService } from '../services/location-information.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-enterprise-view',
  templateUrl: './enterprise-view.component.html',
  styleUrls: ['./enterprise-view.component.scss']
})
export class EnterpriseViewComponent implements OnInit, OnDestroy {
  dataSource:MatTableDataSource<EnterpriseType>
  mDataSource: EnterpriseType[]
  subscription: Subscription;
  currentShift: string = "True";
  enterpriseDataArr: any[];
  dateString: string;

  enterpriseViewDataSource: MatTableDataSource<EnterpriseType> = new MatTableDataSource<EnterpriseType>();

  enterpriseViewData$: Observable<EnterpriseType[]>

  public fromDateString: string;
  public toDateString: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['site', 'minor', 'major', 'critical', 'Acknowledged','total', 'score', 'trendChartButton'];

  constructor(
    private service: EnterpriseViewService,
    private route: ActivatedRoute,
    private locationInformationService: LocationInformationService
  ) {}

  ngOnInit(){
    setTimeout(() => 
      {this.locationInformationService.changeLocation(true);
      this.locationInformationService.changePlantView(false);
      this.locationInformationService.changePlant('');
      this.locationInformationService.changeDetailsView(false);
      this.locationInformationService.changeSeverityView(false);
    })

    this.dataSource = new MatTableDataSource<EnterpriseType>();
  }

  fetch(){
      let d: Observable<GenericLambdaReturn<EnterpriseType[]>> =
      this.route.paramMap.pipe(
        switchMap((params:ParamMap) =>
        this.service.getEnterpriseData(this.currentShift, this.fromDateString + ' 00:00:00', this.toDateString + ' 23:59:59.999',))
      );
    
      if (this.subscription) this.subscription.unsubscribe();
    
    this.subscription = d.subscribe((next) => {
      this.dataSource.data=next.Body;
      this.mDataSource = next.Body;
      this.enterpriseDataArr = next.Body;
      this.dateString = this.fromDateString + '-' + this.toDateString
    })
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }


  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');
    if (e.currentShift) {
      this.currentShift = "True";
    } else {
      this.currentShift = "False";
    }
    this.fetch();
  }

  changePlant(plant: string){
    this.locationInformationService.changePlant(plant)
    this.locationInformationService.changePlantView(true)
  }

}
