<div class="fx-column" id="trendChart">

  <div class="fx-row-center-center" id="options">
    <form [formGroup]="lineSelectForm">
      <mat-form-field>
          <mat-select placeholder="Line Select" formControlName="lines" multiple>
            <mat-option *ngFor="let lines of lineListElements$ | async" [value]="lines">{{lines}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button type="button"
        mat-raised-button
        color ="primary"
        (click)="changeLinesDisplayed()" type="button">
        Submit
        </button>
    </form>
  </div>

    <div class="fx-row-center-center" id="options">
      <gea-mes-datepicker 
      matTooltip="Select Date" 
      (onDateSelect)="onDateSelection($event)" 
      (onDateInit)="onDateSelection($event)"
      singleDate="false" 
      showLabel="true"
      showCurrentShift="true"
      defaultRange="Current Shift"
      timeRangeOptionalInclude="Rolling 6 Months,Rolling 12 Months,Year to Date"
      showRangeLabel="true">
      </gea-mes-datepicker>
    </div>
    
      <ng-template #footerTemplate>
        <button class="btn btn-primary btn-sm btn-xs float-left" (click)="getSeverityTrendData()">Submit</button>
        <button class="btn btn-secondary btn-sm btn-xs float-right" (click)="this.isCalVisible = !this.isCalVisible">Close</button>
      </ng-template>
      
        
      <div class="fx-column-center-center">
        <div *ngIf="showChart" class="mat-elevation-z8 table-container" id="enterpriseTable">
          <fusioncharts 
          width="100%" 
          height="85%" 
          type="msline"
          [chartConfig]=chartConfig
          [dataSource]=dataSource>
          </fusioncharts> 
        </div>
        <div *ngIf="!showChart" class="mat-elevation-z8 table-container">
          <h2>No Data</h2>
        </div>        
      </div>
    </div>
    
