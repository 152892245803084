import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chart-side-nav',
  templateUrl: './chart-side-nav.component.html',
  styleUrls: ['./chart-side-nav.component.scss']
})
export class ChartSideNavComponent implements OnInit {
  public plant: string
  public plantSub: any

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.plant = this.route.firstChild.snapshot.params["plant"]
  }

  ngOnDestroy(){

  }

}
