import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SeverityViewService } from '../services/severity-view.service';
import { saveAs } from 'file-saver';
import { Binary } from '@angular/compiler';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-details-table',
  templateUrl: './details-table.component.html',
  styleUrls: ['./details-table.component.css']
})
export class DetailsTable {
  
  displayTeamName: string;
  detailsFormTitle: string;
  detailsDataSource: any[];
  displayedColumns = [];
  firstimage : number = 0;
  secondimage : number = 0 ;
  resultid :string;
  site: string;
  pic:Blob;
  pic1click:Boolean=false;
  pic2click:Boolean=false;
  base64data :any;
  imageSrc:SafeUrl;
  
  constructor(public dialogRef: MatDialogRef<DetailsTable>,
    @Inject(MAT_DIALOG_DATA) public detailsData: DetailsDialogData,
    private service: SeverityViewService,
    private sanitizer : DomSanitizer
  )

  { 
    console.info(detailsData)
  
    this.detailsDataSource = detailsData.details
    if (detailsData.image1 != undefined){
      this.firstimage = detailsData.image1

    }
    if (detailsData.image2 != undefined){
      this.secondimage = detailsData.image2
  
    }
    this.resultid = detailsData.resultId
    this.site = detailsData.site

    console.log(this.detailsDataSource)
    this.displayedColumns = Object.keys(this.detailsDataSource[0])
    
    console.log(this.displayedColumns)
  
  
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  close(): void {
    this.dialogRef.close();
  }
  getimage(image:string){
    let base64data: any

    this.service.getpic(this.resultid,image,this.site).subscribe(
      
      (blob) => {
        console.debug("Received picture")
        this.pic = blob; // Save the blob to be accessed later
        if (image == 'Image1'){
          this.pic1click = true
        }
        if (image == 'Image2'){
          this.pic2click = true
        }
        var reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = (out) => {
          console.log(' In the function ')
          base64data = reader.result;
          this.imageSrc = this.sanitizer.bypassSecurityTrustUrl( base64data);
        }          },
      (err) => {
        console.error("Error generating picture", err);

      }
      );
  }

}

export interface DetailsDialogData {
  details: any[];
  image1: number
  image2: number
  resultId: string
  site : string
}
