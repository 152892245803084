<div class="fx-row-start-center" color="primary" >
  <div>
    <a    color="primary"
 
       mat-list-item
       routerLink="/apps"
       >
       EOLA : Enterprise View : 
    </a>
  </div>
  <div>
    <a    color="primary"

        mat-list-item
        routerLink="/plantView/{{plant}}"
        >
        {{plant}} 
    </a>
  </div>
  <div>
    <a    color="primary"
      type="text-align"
      >
      : {{line}} : {{severityInfo}}
    </a>
   </div>
</div> 

<div class="flex-column">
  <div class="fx-column-center-center">
    <div class="table-container" id="plantTable">
      <table mat-table [dataSource]="dataSource" matSort aria-label="Elements">
      
        <!-- Result Date Time Column -->
        <ng-container matColumnDef="ResultDateTime" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header >Result Date Time</th>            
        <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Result Date Time:</span>
          {{row.ResultDateTime | date:'medium' }}</td>
      </ng-container>

          <!-- Shift Column -->
          <ng-container matColumnDef="Shift">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
          <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Shift:</span>
            {{row.Shift}}</td>
          </ng-container>

        <!-- Line Column -->
        <ng-container matColumnDef="Line">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Line</th>
          <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Line:</span>
            {{row.Line}}</td>
        </ng-container>

        <!-- Audit Type -->
        <ng-container matColumnDef="AuditType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Audit Type</th>
          <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Audit Type:</span>
            {{row.AuditType}}</td>
        </ng-container>

        <!-- Severity Column -->
        <ng-container matColumnDef="Severity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Severity</th>
          <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Severity:</span>
            {{row.Severity}}</td>
        </ng-container>  

        <!-- Model Column -->
        <ng-container matColumnDef="Model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
          <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Model:</span>
            {{row.Model}}</td>
        </ng-container>

        <!-- Serial Column -->
        <ng-container matColumnDef="Serial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial</th>
          <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Serial:</span>
            {{row.Serial}}</td>
        </ng-container>      

        <!-- Area Description Column -->
        <ng-container matColumnDef="AreaDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Area</th>
            <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Area:</span>
              {{row.AreaDescription}}</td>
          </ng-container>

          <!-- Component Description Column -->
        <ng-container matColumnDef="ComponentDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Component</th>
            <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Component:</span>
              {{row.ComponentDescription}}</td>
          </ng-container>

          <!-- Defect Description Column -->
        <ng-container matColumnDef="DefectDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Defect</th>
            <td mat-cell *matCellDef="let row" class="mobile-column"><span *ngIf="isMobileView" class="mobile-header">Defect:</span>
              {{row.DefectDescription}}</td>
          </ng-container>

        <ng-container matColumnDef="Details">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
            <td mat-cell *matCellDef="let row" class="mobile-last-column"><span *ngIf="isMobileView" class="mobile-header"></span>
              <ng-container *ngIf="firstimage">
                <img [src]="imageSrc1" alt="" style="max-width: 400px;">
              </ng-container>
              <ng-container *ngIf="secondimage">
                <img [src]="imageSrc2" alt="" style="max-width: 400px;">
              </ng-container>               
            </td>
        </ng-container>  

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
