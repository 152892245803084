import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LocationInformationService {


  private locationInfo = new BehaviorSubject<boolean>(false);
  currentLocation$ = this.locationInfo.asObservable();

  private plantInfo = new BehaviorSubject<string>("");
  currentPlant$ = this.plantInfo.asObservable();

  private plantView = new BehaviorSubject<boolean>(false);
  plantView$ = this.plantView.asObservable();

  private detailsView = new BehaviorSubject<boolean>(false);
  detailsView$ = this.detailsView.asObservable();

  private detailsInfo = new BehaviorSubject<string>("");
  detailsInfo$ = this.detailsInfo.asObservable();

  private severityView = new BehaviorSubject<boolean>(false);
  severityView$ = this.severityView.asObservable();

  private severityInfo = new BehaviorSubject<string>("");
  severityInfo$ = this.severityInfo.asObservable();


  constructor() { }

  changeLocation(location: boolean){
    this.locationInfo.next(location)
  }

  changePlant(plant: string){
    this.plantInfo.next(plant)
  }

  changePlantView(plantView: boolean){
    this.plantView.next(plantView)
  }

  changeDetailsView(detialsView: boolean){
    this.detailsView.next(detialsView)
  }

  changeDetailsInfo(detialsInfo: string){
    this.detailsInfo.next(detialsInfo)
  }

  changeSeverityView(severityView: boolean){
    this.severityView.next(severityView)
  }
  changeSeverityInfo(severityInfo: string){
    this.severityInfo.next(severityInfo)
  }


}
