<div class="fx-row-start-center" color="primary" >
  <div>
    <a    color="primary"
 
       mat-list-item
       routerLink="/apps"
       >
       EOLA : Enterprise View : 
    </a>
  </div>
  <div>
    <a    color="primary"

        mat-list-item
        routerLink="/plantView/{{plant}}"
        >
        {{plant}} 
    </a>
  </div>
  <div>
    <a    color="primary"
      type="text-align"
      routerLink="/details/{{plant}}" [queryParams]="{Severity: severity, Line: line}"
      >
      : {{line}} : {{severityInfo}}
    </a>
   </div>
</div> 

<mat-tab-group dynamicHeight>
      <mat-tab label="Pareto">
        <ng-template matTabContent>
          <div style="height: 90vh;">
              <app-pareto></app-pareto>
          </div>
        </ng-template>
      </mat-tab>
</mat-tab-group>

