<div class="fx-row-start-center" color="primary" >
  <div>
    <a    color="primary"
 
       mat-list-item
       routerLink="/apps"
       >
       EOLA : Enterprise View : 
    </a>
  </div>
  <div>
    <a    color="primary"

        mat-list-item
        routerLink="/plantView/{{plant}}"
        >
        {{plant}} 
    </a>
  </div>
  <div>
    <a    color="primary"
      type="text-align"
      >
      : {{line}} : {{severityInfo}}
    </a>
   </div>
</div> 

<div class="flex-column">
  <div class="fx-row-space-evenly-center" id="options">
    <gea-mes-datepicker 
      matTooltip="Select Date" 
      (onDateSelect)="onDateSelection($event)" 
      (onDateInit)="onDateSelection($event)"
      singleDate="false" 
      showLabel="true"
      showCurrentShift="true"
      defaultRange="Current Shift"
      timeRangeOptionalInclude="Rolling 6 Months,Rolling 12 Months,Year to Date"
      showRangeLabel="true">
    </gea-mes-datepicker>
    <app-save-as-csv [dataToWrite]="severityDetailsArr" [dataSource]="'Severity Details'" [dateTimeRange]="dateString"></app-save-as-csv>
    <a routerLink="/charts/trendChart/{{plant}}/{{line}}">
      <button
      mat-raised-button
      color ="primary"
      >
        Trend Chart
      </button> 
    </a>
    <button mat-button class='mat-raised-button' style="border: 0px" color="primary" (click)="navToAnalysis()">Analysis</button>
  </div>


    <div class="fx-column-center-center">
      <div class="mat-elevation-z8 table-container" id="plantTable">
        <table mat-table  class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">

           <!-- Result Date Time Column -->
           <ng-container matColumnDef="ResultDateTime" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Result Date Time</th>
            <td mat-cell *matCellDef="let row">{{row.ResultDateTime | date:'medium' }}</td>
          </ng-container>

           <!-- Shift Column -->
           <ng-container matColumnDef="Shift">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
            <td mat-cell *matCellDef="let row">{{row.Shift}}</td>
          </ng-container>

          <!-- Line Column -->
          <ng-container matColumnDef="Line">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Line</th>
            <td mat-cell *matCellDef="let row">{{row.Line}}</td>
          </ng-container>

          <!-- Model Column -->
          <ng-container matColumnDef="Model">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
            <td mat-cell *matCellDef="let row">{{row.Model}}</td>
          </ng-container>

          <!-- Serial Column -->
          <ng-container matColumnDef="Serial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial</th>
            <td mat-cell *matCellDef="let row">{{row.Serial}}</td>
          </ng-container>

          <!-- Audit Type -->
          <ng-container matColumnDef="AuditType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Audit Type</th>
            <td mat-cell *matCellDef="let row">{{row.AuditType}}</td>
          </ng-container>

          <!-- Severity Column -->
          <ng-container matColumnDef="Severity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Severity</th>
            <td mat-cell *matCellDef="let row">{{row.Severity}}</td>
          </ng-container>
  
          <!-- Acknowledge Column -->
          <ng-container matColumnDef="Acknowledge">
            <th mat-header-cell *matHeaderCellDef>Acknowledge</th>
            <td mat-cell *matCellDef="let row ; let i = index">
            <span *ngIf = "row.Severity == 'CRITICAL' || row.Severity == 'MAJOR'">
              <button mat-button color="success"  *ngIf = "row.Acknowledgeresultid != null" (click)="displayAcknowledge(row.AcknowledgeDetails,row.ResultId,row.site,row.Acknowledgeresultid)" ><mat-icon>verified_user</mat-icon>view</button>
              <button mat-button color="needsreview"  *ngIf = "row.Acknowledgeresultid == null" (click)="displayAcknowledge(row.AcknowledgeDetails,row.ResultId,row.site,row.Acknowledgeresultid)" ><mat-icon>done</mat-icon>Acknowledge</button>
            </span>
          </td>
          </ng-container>  
  
          <!-- Area Description Column -->
          <ng-container matColumnDef="AreaDescription">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Area Description</th>
              <td mat-cell *matCellDef="let row">{{row.AreaDescription}}</td>
            </ng-container>

            <!-- Component Description Column -->
          <ng-container matColumnDef="ComponentDescription">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Component Description</th>
              <td mat-cell *matCellDef="let row">{{row.ComponentDescription}}</td>
            </ng-container>

            <!-- Defect Description Column -->
          <ng-container matColumnDef="DefectDescription">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Defect Description</th>
              <td mat-cell *matCellDef="let row">{{row.DefectDescription}}</td>
            </ng-container>

          <!-- Operator Name Column -->
          <ng-container matColumnDef="OperatorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Operator Name</th>
            <td mat-cell *matCellDef="let row">{{row.OperatorName}}</td>
          </ng-container>

          <!-- Note Column -->
          <ng-container matColumnDef="Notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
            <td mat-cell *matCellDef="let row">{{row.Note}}</td>
          </ng-container>
          
          <ng-container matColumnDef="Details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let row">
              <button mat-button color="primary" *ngIf = "row.NewDetails.length > 0" (click)="displayDetails(row.NewDetails,row.Image1,row.Image2,row.ResultId,row.site)"><mat-icon>list</mat-icon>Details</button>
            </td>
          </ng-container>  


  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>

  </div>
  