import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { LocationInformationService } from 'src/app/services/location-information.service';


@Component({
  selector: 'app-analysis-home',
  templateUrl: './analysis-home.component.html',
  styleUrls: ['./analysis-home.component.scss']
})
export class AnalysisHomeComponent implements OnInit {
  plant:string;
  line:string;
  severity: string;
  severityInfo:string;
  startDate:string;
  endDate:string;
  currentTab:string="pareto";
  queryParam:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private locationInformationService: LocationInformationService
  ) { 
  }

  ngOnInit(): void {
    this.plant = this.route.snapshot.paramMap.get('plant')
    this.route.queryParams.subscribe(params => this.severity=params['Severity'])
    this.route.queryParams.subscribe(params => this.line=params['Line'])
    console.log ( ' Value of severeity ', this.severity )
    setTimeout(() => 
      { this.locationInformationService.changeLocation(true);
        this.locationInformationService.changePlant(this.plant);
        this.locationInformationService.changePlantView(true);
    
        if (typeof this.line !== "undefined"){
          this.locationInformationService.changeDetailsView(true);
          this.locationInformationService.changeDetailsInfo(this.line)
        } else{
          this.locationInformationService.changeDetailsView(false);
        }
        
    
        if (typeof this.severity !== "undefined"){
          this.locationInformationService.changeSeverityView(true);
          this.locationInformationService.changeSeverityInfo(this.severity)
        } else{
          this.locationInformationService.changeSeverityView(true);
          this.locationInformationService.changeSeverityInfo('All');
        }
        this.locationInformationService.severityInfo$.subscribe((severityInfo) => {
          this.severityInfo = severityInfo
          this.queryParam = "{Severity = " + this.severityInfo + "}"
        });
      })
  }

  onDateSelection(e) {
    this.startDate = dayjs(e.start).format('YYYY-MM-DD'); 
    this.endDate = dayjs(e.end).format('YYYY-MM-DD'); 
  }

  changeTab(tabName:string) {
    this.currentTab = tabName;
  }

  navBackToSeverity(){
    
  }
}