import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, Subscription, forkJoin} from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GenericLambdaReturn } from '../models/genericLamba';
import { PlantViewService, PlantType } from '../services/plant-view.service';
import { Router } from '@angular/router';
import { LocationInformationService } from '../services/location-information.service';

import * as dayjs from 'dayjs'

@Component({
  selector: 'app-plant-view',
  templateUrl: './plant-view.component.html',
  styleUrls: ['./plant-view.component.scss']
})
export class PlantViewComponent implements OnInit, OnDestroy {
  dataSource:MatTableDataSource<PlantType>
  mDataSource: PlantType[]
  subscription: Subscription;
  currentShift: string = "True";
  plantDataArr: any[];
  dateString: string;
  
  public fromDateString: string;
  public toDateString: string;
  public plant: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['site', 'minor', 'major', 'critical', 'acknowledged','total', 'score'];

  constructor(private service: PlantViewService, 
              private locationInformationService: LocationInformationService, 
              private router: Router, 
              private route: ActivatedRoute,
              ){
  }
 
  ngOnInit(){
    this.plant = this.route.snapshot.paramMap.get('plant')
    setTimeout(() => 
      {
      console.log(' after call to navitems ' )
      this.locationInformationService.changeLocation(true)
      this.locationInformationService.changePlantView(true)
      this.locationInformationService.changePlant(this.plant)
      this.locationInformationService.changeDetailsView(false)
      this.locationInformationService.changeSeverityView(false)
      }
    )
    this.dataSource = new MatTableDataSource<PlantType>();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  fetch(){
    let d: Observable<GenericLambdaReturn<PlantType[]>> =
      this.route.paramMap.pipe(
        switchMap((params:ParamMap) =>
        this.service.getPlantData(this.currentShift, this.fromDateString + ' 00:00:00', this.toDateString + ' 23:59:59.999', this.plant))
      );
    
      if (this.subscription) this.subscription.unsubscribe();

    this.subscription = d.subscribe((next) => {
      this.dataSource.data=next.Body;
      this.mDataSource = next.Body;
      this.plantDataArr = next.Body;
      this.dateString = this.fromDateString + '-' + this.toDateString 
    })
  }

  onDateSelection(e) {
    console.log("Date Pick????");
    console.log(e);
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');
    if (e.currentShift) {
      this.currentShift = "True";
    } else {
      this.currentShift = "False";
    }
    this.fetch();
  }

  severityDataSelect(severityType: string, plant: string){
    this.router.navigate(['details', plant, {Severity: severityType}]);
  }
}
