import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseViewService extends AbstractRestService {
  postInit(): void {}

  getEnterpriseData(CurrentShift: string = "True", StartDate: string = "", EndDate: string = "") :Observable<GenericLambdaReturn<EnterpriseType[]>> {
    let params:any={
      "CurrentShift": CurrentShift,
      "StartDate": StartDate,
      "EndDate": EndDate,
    };

    return this.get({
      ApiResource: '/enterprise',
      data: params
    });
  }

}

export type EnterpriseType = {
  site: string;
  Critical: number;
  Major: number;
  Minor: number;
  None: number;
  Total: number;
  acknowledged : number;
  Score: number;
}