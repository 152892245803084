import { Pipe, PipeTransform } from '@angular/core';
/*
 * Adds colon to the subtitle 
*/

@Pipe({name: 'subTitleColon'})
export class SubTitleColon implements PipeTransform {
    transform(value: string): string {
        if (value != ''){
            value = " : " + value
        } else{
            value =''
        }
    return value
  }
}