import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class TrendService extends AbstractRestService   {

  postInit(): void { }

  getLines(plant: string):Observable<GenericLambdaReturn<any>> 
  {

    let params:any = {
        'Site': plant
    }

    return this.get({
      ApiResource: "/lines",
      data: params,
      repeatCall: false
    })

  }

  getSampleRate(plant: string, start: string, end: string, line: string):Observable<GenericLambdaReturn<any>> 
  {

    let params:any = {
        'StartDate': start,
        'EndDate': end,
        'Site': plant,
        'Line': line
    }

    return this.get({
      ApiResource: "/trend/samplerate",
      data: params,
      repeatCall: false
    })

  }
  
}
