import { Component, OnInit } from '@angular/core';
import {AdminAreaType, AdminService} from "../services/admin.service";
import {GenericLambdaReturn} from "../models/genericLamba";
import {switchMap} from "rxjs/operators";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {EnterpriseType} from "../services/enterprise-view.service";
import {Observable, Subscription} from "rxjs/index";



@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {

  options = ["AP1","MZL", "DPO"];
  siteSelect = '';

  displayedColumns: string[] = ['AreaDescription', 'AreaCode'];
  dataSource: AdminAreaType[];
  newData: AdminAreaType[] = [];

  subscription: Subscription;

  errorMsg: string;

  constructor(private service: AdminService,
    private route: ActivatedRoute) {}

  ngOnInit(){
  }

  selectChange(e){
    //clear new data before fetching
    this.newData = [];
    this.fetch();
  }

  onSubmit(){
    this.newData.forEach((el) => {
        this.service.areaUpdate(el,this.siteSelect).subscribe();
    });
    //clear new data
    this.newData = [];

  }

// listens for when area codes are modified and determines if input needs to be a part of the new data set
  areaCodeChange(e, description:string){
    if (e.length > 2 && e.length != 1){
      this.errorMsg = 'Area Code must by 2 characters';
    }else{
      this.errorMsg = '';
      let flag = 0;
      this.newData.forEach((el) => {
      if (el.Description == description){
         el.AreaCode = e;
         flag = 1;
      }
      });
      if (!flag){
        let newEl: AdminAreaType= {
          AreaCode:e,
          Description: description
        };
        this.newData.push(newEl);
      }

    }
  }


  fetch() {
    let d: Observable<GenericLambdaReturn<AdminAreaType[]>> =
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.service.getAreaConfigData(this.siteSelect))
      );

    if (this.subscription) this.subscription.unsubscribe();

    this.subscription = d.subscribe((next) => {
      this.dataSource = next.Body;
    })
  }

}
