const eventList:Array<string> = [
  "beforeLinkedItemOpen",
  "linkedItemOpened",
  "beforeLinkedItemClose",
  "linkedItemClosed",
  "printReadyStateChange",
  "dataLoadRequestCompleted",
  "dataLoadError",
  "dataLoadCancelled",
  "dataLoadRequestCancelled",
  "dataUpdated",
  "dataUpdateCancelled",
  "dataLoadRequested",
  "beforeDataUpdate",
  "realTimeUpdateComplete",
  "chartCleared",
  "slicingEnd",
  "slicingStart",
  "entityRollOut",
  "entityRollOver",
  "entityClick",
  "connectorRollOver",
  "connectorRollOut",
  "connectorClick",
  "markerRollOver",
  "markerRollOut",
  "markerClick",
  "pageNavigated",
  "rotationEnd",
  "rotationStart",
  "centerLabelRollover",
  "centerLabelRollout",
  "centerLabelClick",
  "centerLabelChanged",
  "chartClick",
  "chartMouseMove",
  "chartRollOver",
  "chartRollOut",
  "backgroundLoaded",
  "backgroundLoadError",
  "legendItemClicked",
  "legendItemRollover",
  "legendItemRollout",
  "logoRollover",
  "logoRollout",
  "logoClick",
  "logoLoaded",
  "logoLoadError",
  "beforeExport",
  "exported",
  "exportCancelled",
  "beforePrint",
  "printComplete",
  "printCancelled",
  "dataLabelClick",
  "dataLabelRollOver",
  "dataLabelRollOut",
  "scrollStart",
  "scrollEnd",
  "onScroll",
  "zoomReset",
  "zoomedOut",
  "zoomedIn",
  "zoomed",
  "zoomModeChanged",
  "pinned",
  "dataRestored",
  "beforeDataSubmit",
  "dataSubmitError",
  "dataSubmitted",
  "dataSubmitCancelled",
  "chartUpdated",
  "nodeAdded",
  "nodeUpdated",
  "nodeDeleted",
  "connectorAdded",
  "connectorUpdated",
  "connectorDeleted",
  "labelAdded",
  "labelDeleted",
  "selectionRemoved",
  "selectionStart",
  "selectionEnd",
  "labelClick",
  "labelRollOver",
  "labelRollOut",
  "labelDragStart",
  "labelDragEnd",
  "dataplotDragStart",
  "dataplotDragEnd",
  "processClick",
  "processRollOver",
  "processRollOut",
  "categoryClick",
  "categoryRollOver",
  "categoryRollOut",
  "milestoneClick",
  "milestoneRollOver",
  "milestoneRollOut",
  "chartTypeChanged",
  "overlayButtonClick",
  "loaded",
  "rendered",
  "drawComplete",
  "renderComplete",
  "dataInvalid",
  "dataXMLInvalid",
  "dataLoaded",
  "noDataToDisplay",
  "legendPointerDragStart",
  "legendPointerDragStop",
  "legendRangeUpdated",
  "alertComplete",
  "realTimeUpdateError",
  "dataplotRollOver",
  "dataplotRollOut",
  "dataplotClick",
  "linkClicked",
  "beforeRender",
  "renderCancelled",
  "beforeResize",
  "resized",
  "resizeCancelled",
  "beforeDispose",
  "disposed",
  "disposeCancelled",
  "linkedChartInvoked",
  "beforeDrillDown",
  "drillDown",
  "beforeDrillUp",
  "drillUp",
  "drillDownCancelled",
  "drillUpCancelled",
];

export default eventList;
