import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import { LayoutModule } from '@angular/cdk/layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EolaNavComponent } from './eola-nav/eola-nav.component';
import { EnterpriseViewComponent } from './enterprise-view/enterprise-view.component';
import { PlantViewComponent } from './plant-view/plant-view.component';
import { SubTitleColon } from './pipes/subTitle.pipe';
import { SeverityViewComponent } from './severity-view/severity-view.component';
import { TrendChartComponent } from './trend-chart/trend-chart.component';
import { SeverityTrendChartComponent } from './severity-trend-chart/severity-trend-chart.component';
import { ChartSideNavComponent } from './chart-side-nav/chart-side-nav.component';
import { SiteTrendChartComponent } from './site-trend-chart/site-trend-chart.component';
import {AdminComponent} from "./admin/admin.component";
import { GeaMesDatepickerModule } from '@gea-mes/datepicker';
import { SaveAsCsvComponent } from './save-as-csv/save-as-csv.component';
import { TrendSampleRateComponent } from './trend-sample-rate/trend-sample-rate.component';
import { DetailsTable } from './severity-view/details-table.component';
import { AcknowledgeTable } from './severity-view/acknowledge-table.component';
import {GeaMesNavbarModule}from '@gea-mes/navbar';
import { NavItemService } from './nav-item.service';

/** 
 * Fusion Charts
 * 
 * Note 01/03/2023
 * 
 * For Angular 14, angular-fusioncharts module does not work. See issue:
 * 
 * https://github.com/fusioncharts/angular-fusioncharts/issues/153
 * 
 * Used the module copy from https://stackblitz.com/edit/angular-ivy-32bzyf
 * 
 * Had to have a copy of the module in the app itself which is why FusionChartsModule is local to the app.
 * 
 **/ 

import { FusionChartsModule } from './angular-fusioncharts/fusioncharts.module';
import * as FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import { AnalysisHomeComponent } from './analysis/analysis-home/analysis-home.component';
import { ParetoComponent } from './analysis/pareto/pareto.component';
import { AlarmDetailViewComponent } from './alarm-detail-view/alarm-detail-view.component';

@NgModule({
  declarations: [
    AppComponent,
    EolaNavComponent,
    EnterpriseViewComponent,
    PlantViewComponent,
    SubTitleColon,
    SeverityViewComponent,
    TrendChartComponent,
    SeverityViewComponent,
    SeverityTrendChartComponent,
    ChartSideNavComponent,
    SiteTrendChartComponent,
    AdminComponent,
    SaveAsCsvComponent,
    TrendSampleRateComponent,
    DetailsTable,
    AcknowledgeTable,
    AnalysisHomeComponent,
    ParetoComponent,
    AlarmDetailViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FusionChartsModule.forRoot(FusionCharts, Charts, FusionTheme, CandyTheme),
    MatMenuModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FusionChartsModule,
    MatSelectModule,
    MatInputModule,
    GeaMesDatepickerModule,
    GeaMesNavbarModule,
    MatTabsModule
  ],
  providers: [NavItemService],
  bootstrap: [AppComponent]
})
export class AppModule { }
