import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService extends AbstractRestService {
  postInit(): void {}

  pareto(site:string,currentShift:string,startDate:string,endDate:string,line?:string,severity?:string,operator?:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    
    params.Site = site;
    params.CurrentShift = currentShift
    params.StartDate = startDate;
    params.EndDate = endDate;
    if (line != undefined)
      params.Line = line;
    if (severity != undefined)
      params.Severity = severity;
    if (operator != undefined)
      params.Operator = operator;


    return this.get({
      ApiResource: "/details/pareto",
      data: params,
      repeatCall: true
    })
  }
}

export type ParetoType = {
  ComponentDescription :string;
  DefectDescription :string;
  TotalIssues :number;
}
