import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs'
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})

export class TrendReportService extends AbstractRestService {
  postInit(): void {}

  trendDateRange(plant: string, start?: string, end?: string, groupBy?: string, line?: string):Observable<GenericLambdaReturn<any>>{
    let params:any={};

    plant ? params.Site = plant:null;
    start ? params.StartDate = start:dayjs().subtract(7,'d').format('YYYY-MM-DD');
    end ? params.EndDate = end:dayjs().format('YYYY-MM-DD');

    groupBy ? params.GroupBy = groupBy:null;
    line ? params.Line = line:null;

    return this.get({
      ApiResource: "/trend",
      data: params,
      repeatCall: false
    }); 
  }
}
